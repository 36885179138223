import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'debounce';
import { Form, Input } from 'antd';

import defaults from 'DEFAULTS';
import { tConsume } from 'TRANSLATION';
import InputDescription from '@atoms/form/input-description';
import Hint from '@molecules/hint';
import setLabel from '@atoms/form/funcs/setLabel';
//ADDONS
import FormSelect from '@atoms/form/form-item/FormSelect';

const { TextArea, Password } = Input;

class FormInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      focused: false,
      uid: Math.random().toString(),
      autoComplete: 'off',
      type: 'text',
      label: '',
      placeholder: false,
      size: defaults.antd.input.size,
      description: false,
      blurValidation: true,
      disablePaste: false,
      max: false,
      mask: null,
      pasted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.toggleFocus = this.toggleFocus.bind(this);
    this.propsHandleChange = debounce(props.onChange, defaults.input.updateDelay);

    this.input = React.createRef();
  }

  componentDidMount() {
    this._mounted = true;

    const { t, item, textarea } = this.props;

    const label = setLabel(item, t);

    let description = '';

    if (item.description) {
      if (typeof item.description === 'string') {
        description = item.description;
      } else {
        description = t(`field.${item.name}.description`, false);
      }
    }

    const initState = {
      extra: t(`field.${item.name}.extra`, false),
      label: label,
      placeholder: !!item.placeholder,
      max: 'max' in item && !isNaN(parseInt(item.max)) ? parseInt(item.max) : false,
      description: description,
    };

    let value = {};

    if (textarea && item.value) {
      value = { value: item.value.toString().replace(/\\r\\n/g, '\n') };
    }

    if (item.autofocus) {
      this.input.focus();
    }

    this.setState({
      ...item,
      ...value,
      ...initState,
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps) {
    const { _static, disabled, item, t } = this.props;
    if (!_static && disabled !== prevProps.disabled) {
      this.handleChange(null, true);
    }

    if (item.value !== prevProps.item.value && item.value !== this.state.value) {
      this.setState({ value: item.value });
    }

    if (item.label !== prevProps.item.label) {
      this.setState({ label: setLabel(item, t) });
    }
  }

  /**
   *
   * Calls parent change method
   *
   * @param event
   * @param clear
   */
  handleChange(event, clear = false) {
    let value = clear ? '' : event?.target.value;
    let startPos = event?.target.selectionStart || 0;
    let endPos = event?.target.selectionEnd || 0;
    const { _static, item } = this.props;
    const { accept, name } = item;
    const { max, length, mask, value: prevValue, pasted, type } = this.state;
    const trimByMax = type === 'number' ? length : max;
    let tempValue = trimByMax && value.length > trimByMax ? value.slice(0, trimByMax) : value;

    if (accept && !tempValue.match(accept)) {
      return false;
    }

    if (mask && !clear) {
      const maskedData = mask({ newValue: tempValue, prevValue, startPos, endPos, pasted });
      tempValue = maskedData.value;
      startPos = maskedData.hasOwnProperty('startPos') ? maskedData.startPos : startPos;
      endPos = maskedData.hasOwnProperty('endPos') ? maskedData.endPos : endPos;
    }

    const newValue = {
      name: name,
      value: tempValue,
      help: '',
      status: '',
    };

    this.setState({ value: tempValue }, () => {
      // controlled caret
      if (!clear && ['text', 'search', 'url', 'tel', 'password'].indexOf(type) >= 0) {
        this.input.input?.setSelectionRange?.(startPos, endPos);
      }

      if (!_static) {
        this.propsHandleChange(newValue);
        this.setState({ pasted: false });
      }
    });
  }

  /**
   *
   * Calls parrent change method
   *
   * @param value
   */
  handleBlur(value) {
    const { item, onBlur, _static } = this.props;
    const { blurValidation } = this.state;

    this.toggleFocus(false);

    if (!_static && blurValidation && typeof onBlur === 'function') {
      const blurValue = {
        [item.name]: {
          ...item,
          ...{ value: value },
        },
      };

      this.props.onBlur(blurValue);
    }
  }

  handlePaste(event) {
    const { disablePaste } = this.state;
    if (disablePaste) {
      event.preventDefault();
    }

    this.setState({ pasted: true });
  }

  createAddon(type) {
    const { _static, item, loading, disabled } = this.props;
    const addonType = item[type].addonType ? item[type].addonType : 'select';

    if (addonType === 'select') {
      return (
        <FormSelect
          isAddon={type}
          onChange={this.props.onChange}
          item={item}
          loading={loading}
          disabled={disabled}
          _static={_static}
          allowClear={true}
        />
      );
    }

    if (addonType === 'input') {
      return item[type].name;
    }

    return null;
  }

  toggleFocus(state) {
    this.setState({ focused: state });
  }

  render() {
    const { focused, type, name, label, extra, placeholder, autoComplete, size, value } =
      this.state;
    const { _static, item, disabled, loading, textarea, validation, withHint, password } =
      this.props;
    const { status, help, addonAfter, addonBefore, required, description } = item;

    const classes = [];
    let _addonBefore = '';
    let _addonAfter = '';
    let isPhone = false;
    let _placeholder = placeholder ? label : '';

    if (focused) {
      classes.push('isFocused');
    }

    if (required) {
      classes.push('isRequired');
    }

    if (_static || value || !isNaN(parseInt(value))) {
      classes.push('notEmpty');
    }

    if (textarea) {
      classes.push('isTextarea');
    }

    if (!textarea) {
      isPhone = validation.isPhone;

      if (description) {
        classes.push('with-description');
      }

      if (isPhone) {
        classes.push('is-phone');
      }

      if (withHint) {
        classes.push('withHint');
      }

      if (addonBefore && typeof item.addonBefore === 'object' && 'name' in item.addonBefore) {
        _addonBefore = this.createAddon('addonBefore');
      }

      if (item.addonAfter && typeof item.addonAfter === 'object' && 'name' in item.addonAfter) {
        _addonAfter = this.createAddon('addonAfter');
      }
    }

    const ref = (input) => {
      this.input = input;
    };

    return (
      <>
        <Form.Item
          label={placeholder ? '' : label}
          extra={extra}
          validateStatus={status}
          help={help}
          required={required}
          className={classes.join(' ')}
        >
          {_static && !textarea && !password && (
            <Input
              className="staticInput"
              type="text"
              name={name}
              value={value}
              size={size}
              addonAfter={_addonAfter}
              addonBefore={_addonBefore}
              disabled={true}
            />
          )}

          {_static && !textarea && password && (
            <Password
              className="staticInput"
              type="text"
              name={name}
              value={value}
              size={size}
              addonAfter={_addonAfter}
              addonBefore={_addonBefore}
              disabled={true}
            />
          )}

          {!_static && !textarea && !password && (
            <Input
              type={type !== 'number' ? type : 'text'}
              name={name}
              value={value}
              size={size}
              autoComplete={autoComplete}
              addonAfter={_addonAfter}
              addonBefore={_addonBefore}
              onChange={this.handleChange}
              onFocus={() => this.toggleFocus(true)}
              onBlur={(event) => this.handleBlur(event.target.value)}
              disabled={disabled || loading}
              onPaste={this.handlePaste}
              ref={ref}
            />
          )}

          {!_static && !textarea && password && (
            <Password
              type={type !== 'number' ? type : 'text'}
              name={name}
              value={value}
              size={size}
              autoComplete={autoComplete}
              addonAfter={_addonAfter}
              addonBefore={_addonBefore}
              onChange={this.handleChange}
              onFocus={() => this.toggleFocus(true)}
              onBlur={(event) => this.handleBlur(event.target.value)}
              disabled={disabled || loading}
              onPaste={this.handlePaste}
              ref={ref}
            />
          )}

          {_static && textarea && <TextArea autoSize name={name} value={value} disabled={true} />}

          {!_static && textarea && (
            <TextArea
              autoSize
              className="staticTextarea"
              name={name}
              value={value}
              onChange={this.handleChange}
              onFocus={() => this.toggleFocus(true)}
              onBlur={(event) => this.handleBlur(event.target.value)}
              disabled={disabled || loading}
              onPaste={this.handlePaste}
              ref={ref}
            />
          )}

          {_placeholder && (
            <div
              className={`inputLabel ${addonBefore ? 'before' : ''}`}
              style={{
                ...(addonBefore
                  ? { paddingLeft: (addonBefore.width || 120) + 30, left: 'auto' }
                  : {}),
                ...(addonAfter ? { paddingRight: (addonAfter.width || 120) + 20 } : {}),
              }}
            >
              {required && !value && (
                <div
                  className="fieldIsRequired"
                  style={{ ...(addonBefore ? { paddingLeft: addonBefore.width || 120 } : {}) }}
                >
                  *
                </div>
              )}

              <div className="animatedPlaceholder">{_placeholder}</div>
            </div>
          )}

          {withHint && (
            <Hint
              content={withHint.content}
              type={withHint.type ? withHint.type : 'tooltip'}
              data={withHint.data}
            />
          )}
        </Form.Item>
        {description && <InputDescription>{description}</InputDescription>}
      </>
    );
  }
}

export default tConsume(FormInput);

FormInput.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    addonBefore: PropTypes.object,
    addonAfter: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
    placeholder: PropTypes.bool,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    blurValidation: PropTypes.bool,
    disablePaste: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    valueFormatter: PropTypes.func,
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  textarea: PropTypes.bool,
  password: PropTypes.bool,
  validation: PropTypes.object,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  _static: PropTypes.bool,
  withHint: PropTypes.object,
  mask: PropTypes.func,
  t: PropTypes.func.isRequired,
};

FormInput.defaultProps = {
  validation: {},
  onBlur: false,
  textarea: false,
  password: false,
  disabled: false,
  loading: false,
  _static: false,
};
